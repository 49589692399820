<template>
  <section >
    <h2 class="titulo-links-escolha">CADASTRAR PROFESSOR</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="flex md3 sm6 xs12" style="display:none;">
                    <va-input
                      disabled
                      type="number" 
                      v-model="professor.ano"
                      :label="$t('Ano')"
                    />
                  </div>  
                  <div class="flex md4 xs12">
                    <va-select
                      :label="$t('Selecione o professor')"
                      textBy="nome"
                      track-by="id"
                      v-model="professorSelecionado"
                      :options="servidoresALL"
                    />
                  </div>
                 <!-- <div class="flex md5 xs12">
                    <va-select
                      :label="$t('Selecione a turma')"
                      textBy="nome"
                      track-by="id"
                      v-model="turmaSelecionada"
                      :options="turmasALL"
                    />
                  </div>  -->    
                  <div class="flex">
                    <va-button class="mr-2 mb-2"  @click="cadastrar" v-if="professorSelecionado"> {{ $t('Cadastrar') }}</va-button>  
                    <va-button class="mr-2 mb-2" color="danger" @click="this.$router.push({name: 'coordenacao-professores'});">Voltar</va-button> 
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>

import { Professor } from "@/class/professor.js";
import { Calendario } from "@/class/calendario.js";
import { Servidores } from "@/class/servidores.js";
import { turmaEscolar } from "@/class/turma.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  components: {
   
  },
  name: 'Professor',
  data () {
    return {
      professor: {
        servidor_id: null,
        ano: null,
        turma_id: null,
        user_id: store_token_info.usuario_logado,
      },
      professorSelecionado: '',
      servidoresALL: [],
      turmasALL: [],
    }
  },
  methods: {
    clear (field) {
      this[field] = ''
    },
    async buscarCalendarioAtual() {
      let data = await Calendario.calendarioAtual();
      this.professor.ano = data.data.ano;
    },
    async buscarServidoresALL() {
      this.servidoresALL = [];
      //Buscar todos os servidores
      let data = await Servidores.buscarProfessoresNaoHabilitados();
      this.servidoresALL = data.data;
    },
    async buscarTurmaALL() {
      let data = await turmaEscolar.obtemTodos();
      this.turmasALL = data.data;
    },
    async cadastrar() {
      try {
        this.professor.servidor_id = this.professorSelecionado.id;
        const data = await Professor.cadastrar(this.professor);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        await this.buscarServidoresALL();
        this.professorSelecionado = '';
        this.$router.push({name: 'coordenacao-professores'});
      }
      catch(e) {
        alert(e);
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    this.buscarServidoresALL();
//    this.buscarTurmaALL();
    this.buscarCalendarioAtual();
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
